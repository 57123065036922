import React, { Component } from 'react';
import '.././css/getNewPedidov2.css'; // Asegúrate de incluir los estilos necesarios en App.css
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus, faSearch, faTimes, faTrash,  faCheck, faPlus } from '@fortawesome/free-solid-svg-icons';

class GetNewPedidov2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cartCount: 0,
      cartItems: localStorage.getItem('cartItems')?JSON.parse(localStorage.getItem('cartItems'))||[]:[],
      searchQuery: '',
      items:[],
      sw_nueva_orden:false,
      sw_finalizar_orden:false,
      sw_cancelar_orden:false,
      sw_vista_previa:false,
      checked:false,
      checked2:false,
      checked3:false,
      selectedQuantities: {}
    };
  }

  handleAccordionClick = (index) => {
    const accordions = document.querySelectorAll('.accordion');
    accordions.forEach((accordion, i) => {
      if (i !== index) {
        accordion.classList.remove('active');
        accordion.nextElementSibling.style.display = 'none';
      }
    });
    accordions[index].classList.toggle('active');
    const panel = accordions[index].nextElementSibling;
    panel.style.display = panel.style.display === 'block' ? 'none' : 'block';
  };

  /* handleAddToCart = (product, category) => {
    const quantityInput = document.querySelector(`.product[data-product="${product}"] .quantity-input`);
    const quantity = Number(parseFloat(quantityInput.value).toFixed(2));
    this.setState((prevState) => {
      let cartItems = [...prevState.cartItems];
      let cartCount = prevState.cartCount + quantity;
      const existingItem = cartItems.find(item => item.product === product && item.category === category);
      if (existingItem) {
        existingItem.quantity += quantity;
      } else {
        cartItems.push({ product, category, quantity });
      }
      return { cartCount, cartItems };
    }, () => {
      quantityInput.value = 1;
      this.showNotification('Producto agregado al carrito');
      this.updateCartSummary();
      this.updateOrderSummary();
    });
  }; */

  handleSearchInput = (event) => {
    const searchQuery = event.target.value.toLowerCase();
    this.setState({ searchQuery }, () => {
      const products = document.querySelectorAll('.product');
      //const panels = document.querySelectorAll('.panel');
      const accordions = document.querySelectorAll('.accordion');

      accordions.forEach(accordion => {
        accordion.classList.remove('active');
        accordion.nextElementSibling.style.display = 'none';
      });

      products.forEach(product => {
        const productName = product.querySelector('span').innerText.toLowerCase();
        if (productName.includes(this.state.searchQuery)) {
          const panel = product.closest('.panel');
          panel.style.display = 'block';
          panel.previousElementSibling.classList.add('active');
          product.style.display = '';
        } else {
          product.style.display = 'none';
        }
      });
    });
  };

  clearSearch = () => {
    this.setState({ searchQuery: '' }, () => {
      const products = document.querySelectorAll('.product');
      products.forEach(product => {
        product.style.display = '';
      });
      const panels = document.querySelectorAll('.panel');
      panels.forEach(panel => {
        panel.style.display = 'none';
      });
      const accordions = document.querySelectorAll('.accordion');
      accordions.forEach(accordion => {
        accordion.classList.remove('active');
      });
    });
  };

  showNotification = (message) => {
    const notification = document.createElement('div');
    notification.classList.add('notification');
    notification.innerText = message;
    document.body.appendChild(notification);
    setTimeout(() => {
      notification.remove();
    }, 5000);
  };

 /* newOrder = () => {
    this.setState({ cartCount: 0, cartItems: [],selectedQuantities: {} }, () => {
      this.showNotification('Nuevo pedido creado');
      this.updateCartSummary();
      this.updateOrderSummary();
    });
  };

  cancelOrder = () => {
    this.setState({ cartCount: 0, cartItems: [],selectedQuantities: {} }, () => {
      this.showNotification('Pedido cancelado');
      this.updateCartSummary();
      this.updateOrderSummary();
    });
  }; */
  /* 
  updateCartSummary = () => {
    // Implement the logic to update the cart summary here
  };

  updateOrderSummary = () => {
    // Implement the logic to update the order summary here
  }; */


  
         /* {categories.map((category, index) => (
            <div key={category.name}>
              <button className="accordion" onClick={() => this.handleAccordionClick(index)}>{category.name}</button>
              <div className="panel">
                {category.products.map(product => (
                  <div className="product" data-product={product} key={product}>
                    <span>{product}</span>
                    <div>
                      <input type="number" className="quantity-input" min="1" defaultValue="1" />
                      <button className="button add-to-cart" onClick={() => this.handleAddToCart(product, category.name)}><FontAwesomeIcon icon={faCartPlus} />Agregar al carrito</button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))} */

            /* {items.map(item => (
            <div className="grid-container-item" key={"key_item_"+item.id}>
              <div className="lbl_cantidad">
                <div className="quantity">
                  <label>{item.count}</label>
                </div>
              </div>
              <div className="lbl_descripcion">
                <div className="description">
                  <label>{item.name} </label>
                </div>
              </div>
              <div className="lbl_button">
                <div className="image_">
                <img alt="Cargando ..." className="img_icon"  onClick={ () => this.props.eliminarCarrito(item.id)} src={ require('../images/eliminar.png') } />
                </div>
              </div>
            </div>

         ) ) } */
         
  render() {
    const {  searchQuery , selectedQuantities} = this.state;//cartCount, cartItems,
    //const categories = [];
    const items = this.state.items;     
  
    return (
      <div style={{"width":"100%","height":"100%"}}>
        <header>
          <div className="header-buttons">
            <button className="button" onClick={() => this.ver_vista_crear()}><FontAwesomeIcon icon={faPlus} />Crear Nuevo Pedido</button>
            <button className="button" onClick={() => this.ver_finalizar_pedido()}><FontAwesomeIcon icon={faCheck} />Finalizar Pedido</button>
            <button className="button" onClick={() => this.ver_cancelar()}><FontAwesomeIcon icon={faTimes} />Cancelar Pedido</button>
          </div>
          <div className="cart" onClick={() => this.ver_vista_previa()}>
            <FontAwesomeIcon icon={faCartPlus} />
            <span id="cart-count">{parseFloat(this.props.cantidad_carrito).toFixed(2)}</span>
          </div>
        </header>

        <div className="container">
          <div className="search-bar">
            <input type="text" id="productSearch" placeholder="Buscar productos..." value={searchQuery} onChange={this.handleSearchInput} />
            <FontAwesomeIcon icon={faSearch} />
            {  searchQuery && <FontAwesomeIcon icon={faTimes} className="clear-icon" onClick={this.clearSearch} />}
          </div>
          {(items.length > 0)?
            <React.Fragment>
                {items.map((item , index) => (
                    <div key={item.Crubro}>
                    <button className="accordion" onClick={() => this.handleAccordionClick(index)}>{item.Crubro}</button>
                    <div className="panel">
                        {item.Citems.map((ditem , dindex) => (
                        <div className="product" data-product={ditem.component} key={ditem.component}>
                            <span>{ditem.component}</span>
                            <div>
                            <span className="selected-quantity" style={{ color: 'red' }}>{(selectedQuantities[ditem.component])?"("+selectedQuantities[ditem.component]+")":''}</span> 
                            <input type="number" id={"cant"+ditem.id} className="quantity-input" min="1" defaultValue="" />
                            <button className="button add-to-cart"  onClick={ () => this.agregarCarrito(this,ditem.id,ditem.component,0,ditem.precio_venta,ditem.iva)}><FontAwesomeIcon icon={faCartPlus} />Enviar al carrito</button>
                            </div>
                        </div>
                        ))}
                    </div>
                    </div>
                ))}
            </React.Fragment>:null}
        </div>
       {(this.state.sw_nueva_orden)?
        <div id="newOrderModal" className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => this.salir_vista_crear()}>&times;</span>
            <p>¿Estás seguro de que deseas crear un nuevo pedido? Se vaciará el carrito de compras.</p>
            <button className="button" onClick={() => this.salir_vista_crear_vaciar()}>Sí</button>
            <button className="button" onClick={() => this.salir_vista_crear()}>No</button>
          </div>
        </div>:null}


        {(this.state.sw_cancelar_orden)?
        <div id="newOrderModal" className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => this.salir_vista_cancelar()}>&times;</span>
            <p>¿Estás seguro de que deseas cancelar el pedido? Regresará a la anterior Pantalla.</p>
            <button className="button" onClick={() => this.salir_vista_cancelar_regresar()}>Sí</button>
            <button className="button" onClick={() => this.salir_vista_cancelar()}>No</button>
          </div>
        </div>:null}


        {(this.state.sw_vista_previa)?
        <div id="cartModal" className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => this.salir_vista_previa()}>&times;</span>
            <h2>Carrito de Compras</h2>
            <div id="cartSummary">


              {this.props.items_pedidos && this.props.items_pedidos.length > 0 ? (this.props.items_pedidos.map((item, index) => (
                <div className="cart-summary-item" key={`${item.name}-${index}`}>
                  <span className="summary-item-name">{`${item.name}`}</span>
                  <span className="summary-item-quantity">Cantidad: <span className="selected-quantity" style={{ color: 'red' }}>{item.count}</span></span>
                  <div className="summary-actions">
                    <button className="button" onClick={ () => this.eliminarCarrito(item.id,item.name)}><FontAwesomeIcon icon={faTrash} /></button>
                  </div>
                </div>
              ))): (
                 null
              )}


            </div>
          </div>
        </div>:null}
        {(this.state.sw_finalizar_orden)?
        <div id="orderModal" className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => this.salir_finalizar_pedido()}>&times;</span>
            <h2>Resumen del Pedido</h2>
            <div id="orderSummary">
            {this.props.items_pedidos && this.props.items_pedidos.length > 0 ? (this.props.items_pedidos.map((item, index) => (
                <div className="order-summary-item" key={`${item.name}-${index}`}>
                  <span className="summary-item-name">{`${item.name}`}</span>
                  <span className="summary-item-quantity">Cantidad: <span className="selected-quantity" style={{ color: 'red' }}>{item.count}</span></span>
                  <div className="summary-actions">
                    <button className="button" onClick={ () => this.eliminarCarrito(item.id,item.name)}><FontAwesomeIcon icon={faTrash} /></button>
                  </div>
                </div>
              ))): (
                null
             )}
             <div className="order-summary-item" >
              <span className="summary-item-name">Total Artículos: <span className="selected-quantity" style={{ color: 'red' }}>{parseFloat(this.props.total_articulos)} </span></span>
              <span className="summary-item-quantity">Total Cantidad: <span className="selected-quantity" style={{ color: 'red' }}>{parseFloat(this.props.cantidad_carrito).toFixed(2)}</span></span>
             </div>
            </div>
            <form id="orderForm" onSubmit={this.handleOrderSubmit}>
              <div>
                <input type="checkbox" id="checkbox1" required checked={this.state.checked} 
              onChange={this.handleCheckboxChange}  />
                <label htmlFor="checkbox1"><FontAwesomeIcon icon={faCheck} /> Estoy de acuerdo en haber seleccionado correctamente y revisado todos los productos a pedir.</label>
              </div>
              <div>
                <input type="checkbox" id="checkbox2" required checked={this.state.checked2} 
              onChange={this.handleCheckboxChange1}  />
                <label htmlFor="checkbox2"><FontAwesomeIcon icon={faCheck} /> He leído y tengo conocimiento de las políticas de entrega y cobranza. <button onClick={ () => this.vermas()}>Ver más</button></label>
              </div>
              <div>
                <input type="checkbox" id="checkbox3" required checked={this.state.checked3} 
              onChange={this.handleCheckboxChange2}  />
                <label htmlFor="checkbox3"><FontAwesomeIcon icon={faCheck} /> El pedido es para ser retirado en bodega</label>
              </div>
              <button type="button" className="button" onClick={ () => this.getFinalizar()}
              ><FontAwesomeIcon icon={faCheck} />Enviar Pedido</button>
            </form>
          </div>
        </div>:null}


      </div>
    );
  } 
  eliminarCarrito =(id,component)=>{
    this.setState(prevState => {
      const { [component]: _, ...updatedSelectedQuantities } = prevState.selectedQuantities;
      return { selectedQuantities: updatedSelectedQuantities };
    },()=>{
      this.props.eliminarCarrito(id,component)
    });
  }
  handleCheckboxChange = () => {
    this.setState({ checked: !this.state.checked });
  };
  handleCheckboxChange1 = () => {
    this.setState({ checked2: !this.state.checked2 });
  };
  handleCheckboxChange2 = () => {
    this.setState({ checked3: !this.state.checked3 });
  };

  getFinalizar =()=>{
    let checked =  this.state.checked
    let checked2=  this.state.checked2
    let checked3=  this.state.checked3
    if(checked&&checked2){
      this.props.getCrearPedido("",checked3)
    }else{
      alert("Debe aceptar estar de acuerdo")
    }
  }
  vermas =()=>{
    const url = 'https://lcr.neola.ec/politicas.html'; // Especifica la URL aquí
    window.open(url, '_blank');
  }

  salir_finalizar_pedido(){
    this.setState({
        sw_finalizar_orden:false
    })
  }

  ver_finalizar_pedido(){
    this.setState({
        sw_finalizar_orden:true
    })
  }
  ver_cancelar =()=>{
    this.setState({
        sw_cancelar_orden:true
    })
  }
  salir_vista_cancelar=()=>{
    this.setState({
        sw_cancelar_orden:false
    })
  }
  salir_vista_cancelar_regresar =()=>{
    this.setState({
        sw_cancelar_orden:false ,selectedQuantities: {}
    })
    this.props.vaciarCarrito()
    this.props.getHomeModule();
    localStorage.removeItem('cartItems')
  }
  ver_vista_crear =()=>{
    this.setState({
        sw_nueva_orden:true
    }) 
  }
  salir_vista_crear_vaciar =()=>{
    this.state.items.forEach(item => {
      item.Citems.forEach(ditem => {
          const element = document.getElementById("cant" + ditem.id);
          if (element) {
            element.value = "";
          }
      })
    });
    this.setState({
        sw_nueva_orden:false,selectedQuantities: {}
    })
    this.props.vaciarCarrito()
    localStorage.removeItem('cartItems')
  }


  salir_vista_crear =()=>{
    this.setState({
        sw_nueva_orden:false
    })
  }


  ver_vista_previa =()=>{
    this.setState({
        sw_vista_previa:true
    })
  }
  salir_vista_previa =()=>{
    this.setState({
        sw_vista_previa:false
    })
  }

  isNumber=(value)=> {
    return !Number.isNaN(Number(value));
  }
  agregarCarrito =(this_,id,component,items_cantidad_2,precio_venta,iva)=>{
    /* let items_cantidad_ = this.state.items_cantidad
    items_cantidad_[id]=''
    this.setState({
      items_cantidad:items_cantidad_
    },()=> */
    //const quantityInput = document.querySelector(`.product[data-product="${component}"] .quantity-input`);
   // const 
    // console.log(quantityInput)
    const quantity = Number(parseFloat(document.getElementById('cant'+id).value).toFixed(2))
    //console.log(quantity)
    if(this.isNumber(quantity)){
        this.props.agregarCarrito(this_,id,component,quantity,precio_venta,iva)
        
        this.showNotification('Producto agregado al carrito')
        this.setState((prevState) => ({
          selectedQuantities: {
            ...prevState.selectedQuantities,
            [component]: quantity
          }
        }));
    }
  }
  validar_memoria =()=>{
    //console.log(this.state.cartItems)
    let cantidad_carrito = 0
    let selectedQuantities = {};
    let total_articulos =0
    this.state.cartItems.forEach(item => {
      const element = document.getElementById("cant" + item.id);
      if (element) {
        total_articulos = total_articulos +1
        cantidad_carrito = cantidad_carrito + Number(item.count)
        element.value = item.count;
        selectedQuantities[item.name] = item.count;
      }
    });
    if(cantidad_carrito>0){
      this.setState({ selectedQuantities },()=>{
        this.props.getDatamemoria(cantidad_carrito,this.state.cartItems,total_articulos)
      });
    }
  }
  /* onClick={() => this.handleAddToCart(ditem, item.Crubro)} */
  componentDidMount() {
    //this.actualizar_cuentas();
    this.consultarItemAPI();
    
  }
  consultarItemAPI = () => {
    const id_cliente = this.props.id_cliente;
    if(id_cliente>0){
      const url= this.props.url_global + "webservices/PgetProductos/"+id_cliente;
      //console.log(url)
      fetch(url)
        .then(res => res.json())
        .then((data) => {
           let categorias = data.map(() => 1)
           this.setState({items: data,categorias},()=>this.validar_memoria())
           //console.log(categorias)
          
        })
        .catch(console.log)
    }
  }
}

export default GetNewPedidov2;
