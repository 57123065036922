import React, { Component } from 'react';

class getFinalizarCompra extends Component {

  constructor(props) {
    super(props);
    this.state = {
      observacion :'',
      sw_acuerdo:0,
      checked:false,
      checked1:false,
    }
    this.handleChangeF = this.handleChangeF.bind(this);
    
    //this.handleChange = this.handleChange.bind(this);
  }


  handleChangeF(event) {
     let val = event.target.value;
     this.setState({observacion: val});
  }
  eliminarCantidad = (index) => {
    var items_cantidad = this.state.items_cantidad.slice();
    if(isNaN(items_cantidad[index])){
      items_cantidad[index] = 1;
    }else{
      items_cantidad[index] = items_cantidad[index] - 1;
      if(items_cantidad[index]<=0){
        items_cantidad[index] = 1;
      }
    }
    this.setState({items_cantidad});
  }

  handleCheckboxChange = () => {
    this.setState({ checked: !this.state.checked });
  };
  handleCheckboxChange1 = () => {
    this.setState({ checked1: !this.state.checked1 });
  };

  vermas=()=>{
    const url = 'https://lcr.neola.ec/politicas.html'; // Especifica la URL aquí
    window.open(url, '_blank');
  }
 mostrarItemsSeleccionados = () => {
   const items = this.props.items_pedidos;
   const roundTo = require('round-to');
   if(items.length === 0) return null;
   let sms_final ;

   if(this.props.sms_mensaje!==''){
     sms_final= <React.Fragment>
               {this.props.nom_cliente} <br/> <br/>
               {this.props.sms_mensaje} <br/> <br/>
                </React.Fragment>
   }

   let total_pedido = 0;
   if(items.length !== 0){
     items.forEach(item => {
       total_pedido = total_pedido +roundTo( (Number(item.precio_venta) +(Number(item.precio_venta)*item.iva)/100)*Number(item.count) ,2)
     })
   }
   total_pedido=roundTo(total_pedido,2);
   /*({roundTo(Number(item.precio_venta),2)}$+{item.iva} IVA)*/
   return (
     <React.Fragment>
       <center>
        <button style={{"width":"200px"}} className="boton-crear-pedido" onClick={ () => this.props.getFinalizar()}>Regresar</button> </center><br/>
      <div className="titleS">
        Productos Seleccionados
      </div>
         {items.map(item => (
           <div className="grid-container-item" key={"key_item_"+item.id}>
            <div className="lbl_cantidad">
              <div className="quantity">
                <label>{item.count}</label>
              </div>
            </div>
            <div className="lbl_descripcion">
              <div className="description">
                 <label>{item.name} </label>
              </div>
            </div>
            <div className="lbl_button">
              <div className="image_">
               <img alt="Cargando ..." className="img_icon" onClick={ () => this.props.eliminarCarrito(item.id)} src={ require('../images/eliminar.png') } />
              </div>
            </div>
          </div>

         ) ) }

         <div className="div_contenedor_nuevo">
        <div className="container">
          <label>
            <input 
              type="checkbox" 
              checked={this.state.checked} 
              onChange={this.handleCheckboxChange} 
            />
            <span className="texto-confirmacion">Estoy de acuerdo en haber seleccionado correctamente y revisado todos los productos a pedir.</span>
          </label><br/>
          <label>
            <input 
              type="checkbox" 
              checked={this.state.checked1} 
              onChange={this.handleCheckboxChange1} 
            />
            <span className="texto-confirmacion">He leido y tengo conocimiento las políticas de entrega y cobranza.</span> <button  onClick={ () => this.vermas()}>Ver más</button>
          </label>
        </div> <br/>
         <span className="lbl_mensaje_cierre">
         {sms_final}
         </span>
         <button className="button_final"  onClick={ () => this.getFinalizar(this.state.observacion)}>Realizar Pedido</button>
         </div>
     </React.Fragment>
   )
 }  /* <input className="input_txt" style={{ width: "100%" }} type="text" name="observacion" value={this.state.observacion} onChange={this.handleChangeF} placeholder="Observación" /><br/>
 Total Proforma: <b>{total_pedido}</b><br/> */
  getFinalizar =(observacion)=>{
    let checked =  this.state.checked
    if(checked){
      this.props.getCrearPedido(observacion)
    }else{
      alert("Debe aceptar estar de acuerdo")
    }
  }

  render() {

    return (
       <React.Fragment>
        <div className="shopping-cart">
          {this.mostrarItemsSeleccionados()}

        </div>
       </React.Fragment>
    );
  }

}
export default getFinalizarCompra;
