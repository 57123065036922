import React, {Component} from 'react';
//import logo from './logo.svg';
import './App.css';
import GetLoginv2 from './Componentes/getLoginv2';
import GetHome from './Componentes/getHome';
import GetConfiguracion from './Componentes/getConfiguracion';
import './css/getConfiguracion.css';
import './css/getListaPedidos.css';
import './css/getDetallePedido.css';
import './css/getModal.css';
//import datat from "public/data";
class App extends Component {

  state = {
    urlList :[],
    urls : [],
    id_usuario : '0',
    sw_usuario : '' ,
    id_empresa: 0,
    name : 'Sistema de Pedidos On-Line v1' ,
    url_global : "https://grupomn.net/api_cdsol/index.php/",
    usuario : '',
    id_vendedor : 0,
    sms_mensaje : '',
    nombre_maquina : '',
    sw_grabado : '',
    cajas : [],
    id_caja: 0,
    loading :false,
    empresa:''
  }
  //http://localhost/neola/index.php/


  setLoading = () => {
    this.setState({
      loading:true
    })
  }

  setLoadingClose = () => {
    this.setState({
      loading:false
    })
  }


  salir = () => {
    //localStorage.clear();
    localStorage.id_usuario = 0;
    this.setState(
      {
        sw_usuario : '' , id_usuario : '0' ,id_empresa:'0'
      }
    )
    /*, () =>
    {
      localStorage.clear();
    }*/
  }

  setMaquina = (nombre_maquina) => {
    this.setState(
      {
        nombre_maquina
      }
    )
  }

  setIdCaja = (id_caja) => {
    this.setState(
      {
        id_caja
      }
    )
  }


  setURL = (url_global) => {
    this.setState(
      {
        url_global
      }, () => {
        this.getCajaAPI();
      }
    )
  }
  setConfiguracion = () => {
    localStorage.nombre_maquina = this.state.nombre_maquina;
    localStorage.url_global = this.state.url_global;
    localStorage.sw_grabado = 'SI';
    this.getConfiguracionAPI();
    this.setState(
      {
        sw_grabado : 'SI'
      }
    )
  }
  getCajaAPI = () => {
    const url= this.state.url_global + "webservices/getCajas/";
      fetch( url)
      .then(res => res.json())
      .then((data) => {
        this.setState({ cajas: data })
      })
      .catch(console.log)
  }

  getConfiguracionAPI = () => {
    const url=  this.state.url_global + "webservices/getConfiguracion/"+this.state.nombre_maquina+"/"+this.state.id_caja;
    //console.log(url);
    fetch(url)
      .catch(console.log)
  }

  consultarUsuarioAPI = (usuario,clave) => {
    /*const url= this.state.url_global + "webservices/PgetLoginVendedor/"+usuario+"/"+clave;
    fetch(url)
      .then(res => res.json())
      .then((data) => {
        if(data[0].id_usuario){
          if(Number(data[0].id_usuario)>0){
            localStorage.id_usuario = data[0].id_usuario;
            localStorage.sw_usuario = 'vendedor';
            localStorage.usuario = data[0].usuario;
            localStorage.id_vendedor = data[0].id_personal;
            this.setState({id_usuario : data[0].id_usuario, usuario : data[0].usuario, id_vendedor : data[0].id_personal })
          }else{
            this.setState({
              sms_mensaje : 'Usuario o Contraseña Incorrectas'
            })
          }
        }
      })
      .catch(console.log)*/
      const url= this.state.url_global + "webservices/PgetLoginEmpresa/"+usuario+"/"+clave;
    fetch(url)
      .then(res => res.json())
      .then((data) => {
        if(data[0].id_usuario){
          if(Number(data[0].id_usuario)>0){
            localStorage.id_usuario = data[0].id_usuario;
            localStorage.sw_usuario = 'vendedor';
            localStorage.usuario = data[0].usuario;
            localStorage.id_vendedor = data[0].id_personal;
            localStorage.id_empresa = data[0].id_empresa;
            this.setState({id_usuario : data[0].id_usuario, usuario : data[0].usuario, id_vendedor : data[0].id_personal,id_empresa : data[0].id_empresa })
          }else{
            this.setState({
              sms_mensaje : 'Usuario o Contraseña Incorrectas'
            })
          }
        }
      })
      .catch(console.log)
  }

  handleChangeURL  =(event) =>{
    this.setState({url_global: event.target.value,empresa:event.target.options[event.target.selectedIndex].text });
  }
  BuscarUsuario = (usuario , clave) => {

    if(usuario===''){
       alert('Ingrese Usuario');
         return null;
    }
     if(clave===''){
      alert('Ingrese Clave');
        return null;
    }
    this.consultarUsuarioAPI(usuario , clave);
 }

  render() {

    const url_list = this.state.urlList;
    if(url_list.length===0)return null;
    //console.log(url_list)
    let loading;
    if(this.state.loading){
      loading =<div id="loader" className="loader loader-default is-active" data-text=""></div>
    }

    //const urls = this.state.urls;
    //let list_urls= <React.Fragment></React.Fragment>;
    //console.log(urls)
    /*if(urls.length !== 0){
      urls.forEach(url => {
        //console.log(url)
        const tmp = <option value={url.url}>{url.name}</option>
        list_urls = list_urls + tmp;
        //console.log(url.name)
      })
    }*/

    /*let selc = <select className="select-css" value={this.state.url_global} onChange={(e) => {this.handleChangeURL(e)}}>
    {(urls.length !== 0) ?
      urls.map(url => (
        <option key={url.id} value={url.url}>{url.name}</option>
      ))
      : <React.Fragment></React.Fragment>
    }
    </select>*/

    const id_usuario  = this.state.id_usuario;
    const sw_grabado  = this.state.sw_grabado;
    let modulo;
    if(sw_grabado===''){
        modulo =<div className="grid-container-configuracion">
                  <GetConfiguracion setIdCaja={this.setIdCaja} cajas={this.state.cajas} id_caja={this.state.id_caja} getCajaAPI={this.getCajaAPI} setMaquinaImpresora={this.setMaquinaImpresora} setConfiguracion={this.setConfiguracion} setURL={this.setURL} setMaquina={this.setMaquina} nombre_maquina={this.state.nombre_maquina} url_global={this.state.url_global} nombre_maquina_impresoras={this.state.nombre_maquina_impresoras}  />
                </div>
      }else{
          if (id_usuario==='0') {
            modulo = <GetLoginv2 sms_mensaje={this.state.sms_mensaje} BuscarUsuario={this.BuscarUsuario} name={this.state.name}/>;
          } else {
            //selc =null;
            modulo = <GetHome empresa={this.state.empresa} nombre_maquina={this.state.nombre_maquina} setLoadingClose={this.setLoadingClose} setLoading={this.setLoading} usuario={this.state.usuario} id_vendedor={this.state.id_vendedor} salir={this.salir} url_global={this.state.url_global} id_usuario={this.state.id_usuario}  id_empresa={this.state.id_empresa}/>;
          }
      }


    return (
      <React.Fragment>
        {loading}
        {modulo}
        <img className="img_icon_top_none"  alt="Cargando ..." src={require('./images/daily-ui-058.gif')} />
      </React.Fragment>
    );
  }
  componentDidUpdate (){
    const url_list = this.state.urlList;
    if(this.state.nombre_maquina===''){
      this.setState({
        nombre_maquina : "WEB_EQUIPO" ,
        urls : url_list.url_webservices ,
        sw_grabado : "SI",
        url_global: url_list.url_webservices[0].url,
        empresa: url_list.url_webservices[0].name }
      );
    }
  }
  componentDidMount() {

    const axios = require('axios');
    axios.get('./data.json') // JSON File Path
     .then( response => {
       this.setState({
            urlList: response.data
          })
      })
   .catch(function (error) {
     console.log(error);
   });




    //localStorage.sw_grabado="";

    //const UrlList = datat.url_webservices;
    //console.log(UrlList)
    /*this.setState({
      nombre_maquina : "WEB_EQUIPO" ,
      urls : UrlList ,
      sw_grabado : "SI",
      url_global: UrlList[0].url,
      empresa: UrlList[0].name }
    );*/
    /*const sw_grabado  = this.state.sw_grabado;
        if(sw_grabado===''){
          if(localStorage.nombre_maquina){
            this.setState(
              {
                nombre_maquina : localStorage.nombre_maquina ,
                url_global : localStorage.url_global ,
                sw_grabado : localStorage.sw_grabado
              }
            )
            //return null;
          }
        }

    if(localStorage.id_usuario){
      this.setState(
        {
          id_usuario : localStorage.id_usuario,
          sw_usuario : localStorage.sw_usuario,
          usuario : localStorage.usuario,
          id_vendedor : localStorage.id_vendedor,
        }
      )
    //  return null;
  }*/

    //this.consultarRubroAPI();
  }

}

export default App;
