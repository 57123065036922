import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faFileAlt, faExclamationCircle, faDownload, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import '.././css/getListaPedidov2.css'; // Asegúrate de incluir los estilos necesarios en App.css

class GetListaPedidosv2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
        cartCount: 0,
        cartItems: [],
        searchQuery: '',
        orders: [
            {
            orderNumber: '001',
            status: 'pending',
            orderDate: '01/01/2024',
            invoiceNumber: 'F001',
            invoiceDate: '02/01/2024',
            details: [
                { quantity: 2, detail: 'Producto A' },
                { quantity: 3, detail: 'Producto B' }
            ]
            },
            {
            orderNumber: '002',
            status: 'completed',
            orderDate: '02/01/2024',
            invoiceNumber: 'F002',
            invoiceDate: '03/01/2024',
            details: [
                { quantity: 1, detail: 'Producto C' },
                { quantity: 5, detail: 'Producto D' }
            ]
            }
        ],
        filteredOrders: [],
        selectedOrder: null,
        notificationVisible: false,
        id_filtro : 0 ,
        data : [] ,
        detalle : [],
        id_online_pedidos_activo : 0,
        id_online_pedidos_activo_novedad:0,
        id_cliente_tmp :this.props.id_cliente,
        items_cantidad : [],
        observacion :'',
        id_novedad_tmp:0,
        data_novedad:[],
        notificacion:'',
        selecccionOrden:[]
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(event) {
    this.setState({id_filtro: event.target.value,detalle : [],
    id_online_pedidos_activo : 0},
    () =>{this.consultarListaAPI()});

  }
 
  

  openViewModal = (order) => {
    this.setState({ selectedOrder: order });
    document.getElementById('viewModal').style.display = 'block';
  }

  closeViewModal = () => {
    this.setState({ selectedOrder: null });
    document.getElementById('viewModal').style.display = 'none';
  }

  openNovedadModal = (orderNumber) => {
    this.setState({ selectedOrder: this.state.orders.find(order => order.orderNumber === orderNumber) });
    document.getElementById('novedadModal').style.display = 'block';
  }

  closeNovedadModal = () => {
    this.setState({ selectedOrder: null });
    document.getElementById('novedadModal').style.display = 'none';
  }

  closeNotificationModal = () => {
    this.setState({ notificationVisible: false });
  }

  /*  <div  className="divTableBody" key={nota.id_online_pedidos}>
          <div className="divTableRow">
            <div className="divTableCell">{nota.estado}</div>
            <div className="divTableCell" onClick={ () => this.buscarDetalle(nota.id_online_pedidos)}>{nota.secuencial}</div>
            <div className="divTableCell">{nota.fecha_creacion}</div>
            <div className="divTableCell" onClick={ () => this.buscarDetalleN(nota.id_online_pedidos)}>{nota.factura}</div>
            <div className="divTableCell">{nota.fecha_factura}</div>
          </div>
        </div> */
  renderTableNota = () =>{
    const array_nota = this.state.data;
    return (
      array_nota.map(nota => (
        <tr key={nota.id_online_pedidos}>
        <td>{nota.secuencial}</td>
        <td>{nota.estado}</td>
        <td>{nota.fecha_creacion}</td>
        <td>{nota.factura}</td>
        <td>{nota.fecha_factura}</td>
        <td className="actions">
        <button onClick={ () => this.buscarDetalle(nota.id_online_pedidos,nota)}><FontAwesomeIcon icon={faFileAlt}  /> Ver Pedido</button>
        <button onClick={ () => this.buscarDetalleN(nota.id_online_pedidos,nota)}><FontAwesomeIcon icon={faExclamationCircle} /> Ingresar Novedad</button>
        <button ><FontAwesomeIcon icon={faDownload} /> Bajar PDF</button>
        </td>
        </tr>
      ))
    )
}
buscarDetalleN = (id_online_pedidos_activo_novedad,nota) =>{
    this.setState({id_online_pedidos_activo_novedad,selecccionOrden:nota})
}
buscarDetalle = (id_online_pedidos,nota) =>{
    const url = this.props.url_global + "webservices/getDetallePedido/"+id_online_pedidos;
    fetch(url)
      .then(res => res.json())
      .then((data) => {
        console.log(data)
        let items_cantidad = [];
        data.forEach(cpDTC => {
          items_cantidad[cpDTC.id_detalle]=Number(cpDTC.eliminar);
        });
         this.setState({detalle :  data ,id_online_pedidos_activo:id_online_pedidos,items_cantidad,selecccionOrden:nota})
      })
      .catch(console.log)
  }

  /* {filteredOrders.map(order => (
                <tr key={order.orderNumber} data-status={order.status}>
                  <td>{order.orderNumber}</td>
                  <td>{order.status.charAt(0).toUpperCase() + order.status.slice(1)}</td>
                  <td>{order.orderDate}</td>
                  <td>{order.invoiceNumber}</td>
                  <td>{order.invoiceDate}</td>
                  <td className="actions">
                    <button onClick={() => this.openViewModal(order)}><FontAwesomeIcon icon={faFileAlt} /> Ver Documento</button>
                    <button onClick={() => this.openNovedadModal(order.orderNumber)}><FontAwesomeIcon icon={faExclamationCircle} /> Ingresar Novedad</button>
                    <button><FontAwesomeIcon icon={faDownload} /> Bajar PDF</button>
                  </td>
                </tr>
              ))} */

  render() {
    //const { filteredOrders, selectedOrder, notificationVisible } = this.state;
    let nom =""
    if(this.props.clientes){
        if(this.props.clientes.length>0){
            nom = this.props.clientes[0].nom
        }
    }
    return (
      <div style={{"width":"100%","height":"100%"}}>
        <header>
          <div>
            <h1>Gestión de Pedidos</h1>
          </div>
        </header>

        <div className="container">
          <div className="top-actions">
            <button className="new-order-button" onClick={ () => this.props.getFinalizar()}>
              <FontAwesomeIcon icon={faPlus} /> Crear Nuevo Pedido
            </button>
            <button onClick={ () => this.buscarDetalleN(-1,null)} className="new-order-button" >
              <FontAwesomeIcon icon={faPlus} /> Crear Novedad Extraordinaria
            </button>
            <select value={this.state.id_filtro} onChange={(e) => {this.handleChange(e)}}>
                <option value="0">Pendientes</option>
                <option value="1">Facturadas</option>
                <option value="3">Anulados</option>
                <option value="2">Todos</option>
            </select>
          </div>
          <div className="user-info">
            <h2>Hola, <span>{nom}</span></h2>
            <button onClick={() => this.props.getExitModule()}>Finalizar Sesión</button>
          </div>
          <table>
            <thead>
              <tr>
                <th>Número de Pedido</th>
                <th>Estado</th>
                <th>Fecha de Creación</th>
                <th>Número de Factura</th>
                <th>Fecha de Factura</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
                {this.renderTableNota()}
            </tbody>
          </table>
        </div>

     

        {this.state.id_online_pedidos_activo && (
          <div id="viewModal" className="modal">
            <div className="modal-content">
              <span className="close" onClick={ () => this.encerar_detalle()}>&times;</span>
              <h2>Detalle del Pedido</h2>
              <div className="form-group">
                <label>Número de Pedido</label>
                <span>{this.state.selecccionOrden.secuencial}</span>
              </div>
              <div className="form-group">
                <label>Fecha de Pedido</label>
                <span>{this.state.selecccionOrden.fecha_creacion}</span>
              </div>
              <div className="form-group">
                <label>Estado</label>
                <span>{this.state.selecccionOrden.estado}</span>
              </div>
              <div className="form-group">
                <label>Número de Factura</label>
                <span>{this.state.selecccionOrden.factura}</span>
              </div>
              <div className="form-group">
                <label>Fecha de Factura</label>
                <span>{this.state.selecccionOrden.fecha_factura}</span>
              </div>
              <div className="form-group">
                <label>Detalle del Pedido</label>
                <table>
                  <thead>
                    <tr>
                      <th>Cantidad</th>
                      <th>Detalle</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.detalle && this.state.detalle.length > 0 ? (this.state.detalle.map((nota,index) => (
                      <tr key={index}>
                        <td>{nota.cantidad}</td>
                        <td>{nota.item}</td>
                      </tr>
                    ))):null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}

        {this.state.id_online_pedidos_activo_novedad && (
          <div id="novedadModal" className="modal">
            <div className="modal-content">
              <span className="close" onClick={ () => this.encerar_detalle()}>&times;</span>
              <h2>Ingresar Novedad</h2>
              <form>
                <div className="form-group">
                  <label>Número de Pedido</label>
                  <input type="text" value={(this.state.selecccionOrden)?this.state.selecccionOrden.secuencial:'ExtraOrdinaria'} readOnly />
                </div>
                <div className="form-group">
                  <label>Tipo de Novedad</label>
                  <select>
                    <option value="Producto Dañado">Producto Dañado</option>
                    <option value="Producto no Pedido">Producto no Pedido</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>Observación</label>
                  <textarea rows="4"></textarea>
                </div>
                <div className="form-group">
                  <button type="submit">Enviar Novedad</button>
                </div>
              </form>
            </div>
          </div>
        )}

        {this.state.notificationVisible && (
          <div id="notificationModal" className="modal notification-modal">
            <div className="modal-content">
              <span className="close" onClick={this.closeNotificationModal}>&times;</span>
              <div className="notification-header">
                <FontAwesomeIcon icon={faExclamationTriangle} className="notification-icon" />
                <h2>Notificación</h2>
              </div>
              <p>Estimado Franquiciado,<br/> {this.state.notificacion}</p>
            </div>
          </div>
        )}
      </div>
    );
  }

  encerar_detalle =() =>{
    this.setState({detalle :  [] ,id_online_pedidos_activo:0,id_online_pedidos_activo_novedad:0,observacion:'',notificacion:''})
  }


  componentDidMount() {
    
    this.setState({ filteredOrders: this.state.orders },()=>{
      this.consultarListaAPI();
      this.buscarNovedades();
      this.consultarListaAPIN();
    });

  }
  consultarListaAPIN = () => {
    let url = this.props.url_global + "webservices/getNotificacion";
    fetch(url)
      .then(res => res.json())
      .then((data) => {
            if(data[0].msg){
              this.setState({notificacion:data[0].msg,notificationVisible:true})
            }
      })
      .catch(console.log)
  }
  buscarNovedades = (id_online_pedidos) =>{
    const url = this.props.url_global + "webservices/getCategoriaNovedad/";
    fetch(url)
      .then(res => res.json())
      .then((data) => {
         this.setState({data_novedad:data})
      })
      .catch(console.log)
  }

  consultarListaAPI = () => {
    let url;
    const id_filtro = this.state.id_filtro;
    if(Number(id_filtro)===0){
       url = this.props.url_global + "webservices/getPedidosPendientes/"+this.props.id_usuario+"/"+this.state.id_cliente_tmp;
    }
    if(Number(id_filtro)===1){
       url = this.props.url_global + "webservices/getPedidosFacturados/"+this.props.id_usuario+"/"+this.state.id_cliente_tmp;
    }
    if(Number(id_filtro)===2){
      url = this.props.url_global + "webservices/getPedidosTodos/"+this.props.id_usuario+"/"+this.state.id_cliente_tmp;
    }
    if(Number(id_filtro)===3){
        url = this.props.url_global + "webservices/getPedidosAnulados/"+this.props.id_usuario+"/"+this.state.id_cliente_tmp;
    }

    fetch(url)
      .then(res => res.json())
      .then((data) => {
         if(data[0].status==='1'){
            this.setState({data})
         }else{
           this.setState({data : []})
         }
      })
      .catch(console.log)
  }
}

export default GetListaPedidosv2;
