import React, { Component } from 'react';
import '.././css/getLoginv2.css'; // Asegúrate de incluir los estilos necesarios en App.css

class GetLoginv2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            usuario: '',clave: ''
        }
        this.handleChange = this.handleChange.bind(this);
    } 
    handleChange(event) {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({[nam]: val});
    }
    render() {
        return (
        <div className="login-container">
            <img src="https://www.lcr.neola.ec/static/media/LOGO_LCR.f86a1c8a.png" alt="Logo" />
            <h2>Sistema de Pedidos Online v1</h2>
            <form>
            <input type="text" name="usuario" value={this.state.usuario} onChange={this.handleChange} placeholder="Usuario" required />
            <input type="password" name="clave" value={this.state.clave} onChange={this.handleChange}  placeholder="Clave" required />
            <button type="button" onClick={() => this.handlegetUser()}>Ingresar al Sistema</button>
            <span>{this.props.sms_mensaje}</span>
            </form>
        </div>
        );
    }
    handlegetUser = () => {
        const usuario = this.state.usuario;
        const clave = this.state.clave;
        this.props.BuscarUsuario(usuario, clave);
    };
}

export default GetLoginv2;